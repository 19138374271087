.CarMenuBox {
  width: 200px;
  height: 300px;
  .Title {
    margin-top: 60px;
    font-weight: 600;
    font-size: 20px;
    color: #535353;
    padding-bottom: 40px;
  }
  > a {
    text-decoration: none;
    color: #a38383;
  }
  > a:active {
    color: #da2320;
  }
  .Company_Car_navlink_container {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 14px;
    padding-bottom: 19px;
    position: relative;
    text-decoration: none;
    &.active {
      > div {
        color: red;
      }
    }
    > div {
      color: black;
    }
    > img {
      position: absolute;
      left: 120px;
    }
  }
}

@media screen and (max-width: 768px) {
  .CarMenuBox {
    display: flex;
    max-width: 768px;
    width: 100%;
    height: 50px;
    justify-content: space-around;
    align-items: center;
    .Title {
      margin-top: 0;
      padding-bottom: initial;
      display: flex;
      align-items: center;
      width: 100%;
    }
    > a {
    }
    > a:active {
    }
    .Company_Car_navlink_container {
      font-size: 11px;
      padding: 3px;
      &.active {
        > div {
        }
      }
      > div {
      }
      > img {
        display: none;
      }
    }
  }
}
