.Hanbit_Contents_Area {
  max-width: 1050px;
  width: 100%;
  padding-left: 85px;
  .Title_One {
    font-size: 30px;
    margin-top: 60px;
    padding-bottom: 30px;
    font-weight: 600;
  }
}

@media screen and (max-width: 768px) {
  .Hanbit_Contents_Area {
    margin-top: initial;
    width: initial;
    padding: 0 20px;
    .Title_One {
      
      display: none;
      margin-top: initial;
      font-size: 24px;
      padding: 60px 0px 30px 0px;
    }
  }
}
