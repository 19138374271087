.LoginContainer {
  max-width: 1250px;
  width: 100%;
  text-align: center;
  height: 600px;
  margin: auto;
  .TextBox {
    border-bottom: solid 3px #535353;
    max-width: 392px;
    margin: auto;
    .LoginTitle {
      padding-top: 101px;
      padding-bottom: 30px;
      font-size: 30px;
      font-weight: 600;
    }
  }
  .LoginBox {
    max-width: 392px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    .Id {
      margin-top: 30px;
      width: 392px;
      height: 40px;
      margin-bottom: 10px;
      padding: 0 8px;
    }
    ::-webkit-input-placeholder {
      color: #bfc0bf;
      font-weight: 300;
    }
    .Password {
      width: 392px;
      height: 40px;
      margin-bottom: 13px;
      padding: 0 8px;
    }
    .Middle {
      display: flex;
      text-align: center;
      margin-bottom: 31px;
      .CheckBox {
        &:hover {
          cursor: pointer;
        }
      }
      .MiddleText {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 300;
        padding-left: 5px;
      }
    }
    .LogBtn {
      width: 392px;
      height: 50px;
      background-color: #535353;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
    }
    .Account {
      display: flex;
      font-size: 14px;
      font-weight: 300;
      padding-top: 31px;
      text-decoration: none;
      margin: auto;
      cursor: pointer;
      > a {
        text-decoration: none;
        font-weight: 600;
        color: #070707;
      }
    }
  }
  .Login_Text {
    margin-top: 60px;
  }
}

@media screen and (max-width: 768px) {
  .LoginContainer {
    padding: 0 15px;
    .TextBox {
      .LoginTitle {
      }
    }
    .LoginBox {
      align-items: center;
      .Id {
        max-width: initial;
        width: 100%;
      }
      ::-webkit-input-placeholder {
      }
      .Password {
        width: 100%;
      }
      .Middle {
        display: none;
      }
      .LogBtn {
        width: 100%;
        &:hover {
        }
      }
      .Account {
        > a {
        }
      }
    }
    .Login_Text {
    }
  }
}
