.Chart_Container {
  padding-left: 85px;
  .analysis_chart_box_one {
    .Chart_Title_title {
      margin-top: 60px;
      font-size: 30px;
      color: #535353;
      font-weight: 600;
      padding-bottom: 41px;
    }
  }
  .Chart_analysis_textbox {
    display: flex;
    padding-bottom: 29px;
    .Chart_icon_area {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        width: 23px;
        height: 23px;
      }
    }
    .Chart_Text_Area {
      font-size: 20px;
      color: #da2320;
      padding-left: 5px;
      font-weight: 500;
    }
  }
  .Chart_Box {
    .Chart_Type {
      display: flex;
      font-size: 16px;
      font-weight: 600;
      justify-content: space-between;
      border-top: solid 2px #535353;
      .Type_Text_One {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 225px;
        width: 100%;
        height: 60px;
      }
      .Type_Text_Two {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 430px;
        width: 100%;
        height: 60px;
      }
      .Type_Text_Three {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 400px;
        width: 100%;
        height: 60px;
      }
    }
    .Chart_ListBox {
      .Text_List {
        display: flex;
        justify-content: space-around;
        border-bottom: solid 1px #e5e5e5;
        .analysis_chart_text_one {
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 130px;
          width: 100%;
          height: 60px;
          color: #707070;
        }
        .analysis_chart_text_two {
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 280px;
          width: 100%;
          height: 60px;
          color: #707070;
        }
        .analysis_chart_text_three {
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 225px;
          width: 100%;
          height: 60px;
          color: #707070;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Chart_Container {
    padding-left: initial;
    .analysis_chart_box_one {
      .Chart_Title_title {
        padding-left: 10px;
        font-size: 20px;
        font-weight: 600;
      }
    }
    .Chart_analysis_textbox {
      padding-left: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      .Chart_Text_Area {
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .Chart_Box {
      .Chart_Type {
        font-size: 14px;
        .Type_Text_One {
        }
        .Type_Text_Two {
        }
        .Type_Text_Three {
        }
      }
      .Chart_ListBox {
        border-bottom: solid 1px #535353;
        .Text_List {
          font-size: 11px;
          .analysis_chart_text_one {
            text-align: center;
          }
          .analysis_chart_text_two {
            text-align: center;
          }
          .analysis_chart_text_three {
            text-align: center;
          }
        }
      }
    }
  }
}
