.Vehicle_Container {
  max-width: 1050px;
  width: 100%;
  .Vehicle_TitleBox {
    padding-top: 60px;
    .Vehicle_Title {
      font-size: 30px;
      font-weight: 600;
      padding-bottom: 30px;
    }
  }
  .Vehicle_TopLine {
    display: flex;
    font-size: 16px;
    max-width: 1050px;
    width: 100%;
    height: 60px;
    justify-content: space-between;
    border-top: solid 2px #535353;
    font-weight: 600;
    border-bottom: solid 1px #535353;
    .Top_Line_1 {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
    }
    .Top_Line_2 {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
    }
    .Top_Line_3 {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 5;
    }
  }
  .Vehicle_Board1 {
    max-width: 1050px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 250px;
    .Vehicle_No {
      padding-top: 30px;
      font-size: 18px;
      color: #a0a0a0;
    }
  }
}

@media screen and (max-width: 768px) {
  .Vehicle_Container {
    .Vehicle_TitleBox {
      .Vehicle_Title {
        font-size: 24px;
      }
    }
  }
}
