// .Chart_Container{
//     .Chart_TitleBox{

//         .Chart_Title_title{
//             padding-top: 20px;
//             font-size: 18px;
//             color: #707070 ;
//             padding-bottom: 21px;
//             font-weight: 500;
//         }
//     }
// .Chart{
//     max-width: 1050px;
//     width: 100%;
//     border-top: solid 1px #535353;
//     border-bottom: solid 1px #535353;
.Chart_Box {
  max-width: 1050px;
  width: 100%;
  .Chart_Type {
    display: flex;

    border-bottom: 1px solid #535353;
    &:nth-child(2) {
      color: #da2320;
    }

    .Type_One {
      width: 230px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
    }
    .Type_Two {
      width: 850px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
    }
  }
  .Chart_map_text_box {
    display: flex;
    flex-direction: column;
    .Text_List {
      display: flex;
      border-bottom: solid 1px #e5e5e5;
      color: #707070;

      .One {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 60px;
        font-weight: 500;
      }
      .Two {
        display: flex;
        justify-content: left;
        align-items: center;
        width: 766px;
        height: 60px;
        font-weight: 500;
      }
    }
  }
}
// }

@media screen and (max-width: 768px) {
  // .Chart_Container{
  .Chart_TitleBox {
    .Chart_Title_title {
      padding-left: 10px;
      font-size: 14px;
    }
  }
  .Chart_Box {
    padding: 0 20px;
    .Chart_Type {
      .Type_One {
        font-size: 12px;
      }
      .Type_Two {
        font-size: 12px;
      }
    }
    .Chart_map_text_box {
      .Text_List {
        .One {
          font-size: 11px;
        }
        .Two {
          font-size: 11px;
          padding-right: 15px;
        }
      }
    }
  }
}
// }
