.InHanbit_Contatiner{
    max-width: 1250px;
    margin: auto;
    height: auto;
    .TopBanner{

    }
    .CeoContents{
        display: flex;
    }
    .Result{
        max-width: 1050px;
        margin: auto;
    }
}