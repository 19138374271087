.IntroBox {
  width: 200px;
  height: 150px;
  .Title {
    margin-top: 60px;
    font-weight: 600;
    font-size: 20px;
    color: #535353;
    padding-bottom: 40px;
  }
  > a {
    text-decoration: none;
    color: #a38383;
  }
  > a:active {
    color: #da2320;
  }
  .IntroductionBox_navlink_container {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 14px;
    padding-bottom: 19px;
    position: relative;
    text-decoration: none;
    &.active {
      > div {
        color: red;
      }
    }
    > div {
      color: #535353;
    }
    > img {
      position: absolute;
      left: 120px;
    }
  }
}

@media screen and (max-width: 768px) {
  .IntroBox {
    margin-left: 0px;
    display: flex;
    max-width: 768px;
    width: 100%;
    height: 50px;
    justify-content: center;
    .Title {
      margin-top: 0px;
      width: 100%;
    }
    .IntroductionBox_navlink_container {
      padding: 10px;
      font-size: 10px;
      > img {
        display: none;
      }
    }
  }
}
