.BannerContainer {
  min-width: 1250px;
  width: 100%;
  .BannerBox {
    height: 400px;
    .slick-slider {
      height: 100%;
    }
    .slick-list {
      height: 100%;
    }
    .Slick_box {
      img {
        width: 100%;
        height: 400px;
      }
    }
    .slick-dots li button:before {
      color: #fff;
    }
    .slick-prev:before {
      display: none;
      // position: absolute;
      // color: black;
      // transform: translateY(-50%);
      // font-size: 28px;
    }
    .slick-next:before {
      display: none;
      // position: absolute;
      // color: black;
      // z-index: 1;
      // transform: translate(150px, -50%);
      // font-size: 28px;
    }
    .slick-dots li.slick-active button:before {
      color: #da2320;
    }
    .slick-dots li {
      margin: 0 0;
    }
  }

  .Banner_TextBox {
    width: 1250px;
    position: relative;
    z-index: 1;
    top: -205px;
    left: 55%;
    transform: translate(-50%, -50%);

    .Banner_Title {
      font-weight: 900;
      max-width: 500px;
      width: 100%;
      font-size: 42.5px;
      line-height: 1.34;
      color: #333333;
      text-align: left;
      margin-bottom: 5px;
      margin-top: 5px;
    }
  }

  .Banner_Subtitle {
    font-weight: 500;
    font-size: 26px;
    color: #333333;
  }
}
.Banner_TextBox_right {
  width: 1250px;
  position: relative;
  z-index: 1;
  top: -205px;
  left: 80%;
  transform: translate(-50%, -50%);

  .Banner_Title_right {
    font-weight: 900;
    max-width: 500px;
    width: 100%;
    font-size: 42.5px;
    line-height: 1.34;
    color: #333333;
    text-align: right;
    margin-bottom: 30px;
  }
  .Banner_Subtitle_right {
    max-width: 500px;
    font-weight: 300;
    font-size: 18.5px;
    color: #333333;
    display: flex;
    justify-content: flex-end;
  }
}
.slick-dots {
  bottom: 13px !important;
}
.slick-arrow {
  background-size: cover !important;
}
.slick-prev {
  left: 14% !important;
  z-index: 3;
  background-image: url(../../../_asset/image/main/banner-arrow-icon-1.png) !important;
  background-repeat: no-repeat !important;
  width: 30px !important;
  height: 30px !important;
}

.slick-next {
  right: 18% !important;
  z-index: 3;
  background-image: url(../../../_asset/image/main/banner-arrow-icon-2.png) !important;
  width: 30px !important;
  height: 30px !important;
}

@media screen and (max-width: 768px) {
  .BannerContainer {
    min-width: initial;
    display: none;
    .BannerBox {
      height: 400px;
      .Slick_box {
        img {
          width: 100%;
          height: initial;
        }
      }
    }
    .Banner_TextBox {
      width: 100%;
      top: -200px;
      /* left: 20px; */
      margin: 0;
      padding-left: 15px;
      .Banner_Title {
        font-size: 23px;
        margin: 0;
      }
      .Banner_Subtitle {
        display: none;
      }
    }
  }
  .slick-next {
    left: -30px;
  }
}
