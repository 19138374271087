.NaviContainer {
  min-width: 1250px;
  display: flex;
  justify-content: center;
  height: 73px;
  margin: auto;
  position: relative;
  align-items: center;
  .NaviWrap {
    width: 100%;
    max-width: 1250px;
  }
  .NaviContainer_submenu_container {
    position: absolute;
    background: #fff;
    width: 100%;
    border-top: 1px solid #e5e5e5;
    bottom: -54px;
    opacity: 0.9;
    z-index: 2;
    .NaviContainer_submenu_wrap {
      width: 100%;
      max-width: 1250px;
      margin: auto;
      bottom: 0;
      display: flex;
      padding: 17px 0;
      padding-left: 30px;
      &.align-center {
        justify-content: center;
        transform: translateX(-105px);
      }
      &.align-center2 {
        justify-content: center;
        transform: translateX(45px);
      }
      &.align-left {
        justify-content: flex-start;
        padding-left: 10px;
        transform: translateX(-10px);
      }
      &.align-left2 {
        justify-content: flex-start;
        padding-left: 10px;
        transform: translateX(200px);
      }

      > div {
        font-size: 15px;
        padding-right: 15px;
      }
      > a {
        text-decoration: none;
        color: #535353;
        padding: 0 30px;
      }
      a:hover {
        color: #da2320;
      }
      .Submenu_link {
        &.active {
          color: #da2320;
        }
      }
    }
  }
  .TopNavi {
    display: flex;
    justify-content: space-between;
    max-width: 1250px;
    width: 100%;
    align-items: center;
    .Login_area {
      font-size: 13px;
      > a {
        color: #535353;
      }
    }

    .TopNaviWrap {
      display: flex;
      align-items: center;
      .Menu_Logo {
        max-width: 119px;
        height: 43px;
        cursor: pointer;
        > img {
          width: 100%;
        }
      }
    }

    .Member_Box {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 300;
    }
  }

  ////////////////////////////@extend/* Position and sizing of burger button */
}

.bm-burger-button {
  //메뉴 안보이게하기
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: initial;
  height: initial;
  outline: none;
  width: 30px;
  height: 30px;
  display: none;
  button {
    outline: none;
  }
}
.menu-item {
  color: #444444;
  font-weight: bold;
  text-decoration-line: unset;
  outline: none !important;
}

.bm-item {
}
.bm-cross-button {
  position: absolute;
  right: 13px !important;
  top: 23px !important;
  outline: none !important;
}
/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  color: rgb(220, 49, 50);
}

/* Color/shape of close button cross */
.bm-cross {
}

// .bm-icon {
//     width: 22px !important;
//     height: auto !important;
// }

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */

.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 300px !important;
  top: 0px;
  z-index: 2;
}

/* General sidebar styles */
.bm-menu {
  background: #fdfdfd;
  padding: 7.5em 3.5em 0;
  font-size: 1.15em;
  overflow: hidden !important;
}
.menu-item {
  display: flex !important;
  align-items: center;
  div {
    margin-left: 15px;
    font-size: 14px;
    font-weight: 600;
  }
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  line-height: 64px;
  height: 95%;
  font-family: "NotoSerifCJKkr";
  :hover {
    // text-decoration-line: line-through;
    text-decoration-color: #333333;
    color: rgb(220, 49, 50);
  }
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: #444444;
  font-weight: 600;
  text-decoration-line: unset;
  outline: none !important;
  :hover {
    // text-decoration: line-through;
    text-decoration-color: #333333;
    color: rgb(220, 49, 50);
  }
}

/* Styling of overlay */
.bm-overlay {
  // display: none;
  // background: rgba(0, 0, 0, 0.3);
  display: absolute;
  left: 0px;
  top: 0px;
}
// .menu-item.active{
//     color : #333333;
//     outline: none;
//     text-decoration-line:line-through ;

// }
.bm-item.active {
  // text-decoration-line: line-through;
  text-decoration-color: #333333;
  color: rgb(220, 49, 50);
}

@media screen and (max-width: 768px) {
  .NaviContainer_submenu_wrap {
    display: none;
  }
  .NaviContainer {
    min-width: initial;
    -webkit-box-shadow: 0px 3px 9px -4px rgba(0, 0, 0, 0.11);
    -moz-box-shadow: 0px 3px 9px -4px rgba(0, 0, 0, 0.11);
    box-shadow: 0px 3px 9px -4px rgba(0, 0, 0, 0.11);
    .bm-menu-wrap {
      width: 55% !important;
    }
    .bm-burger-button {
      display: initial;
      > img {
        padding-right: 15px;
      }
    }
    .bm-menu {
      padding: 2.5em 1.5em 0;
    }
    .TopNavi {
      .Login_area {
        display: none;
      }
      .TopNaviWrap {
        margin-left: 0px;
        padding-left: 15px;
        .Menu_Logo {
        }
      }
      .Member_Box {
      }
    }
    .Mobile_Login {
      font-size: 10px;
    }
  }
}
