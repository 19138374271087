.Board {
  border-bottom: solid 1px #e5e5e5;
  justify-content: space-around;
  display: flex;
  .Han_Number {
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .Han_CreatedDate {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  }
  .Han_LastDate {
    flex: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  }
  .Han_Date {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  }
  .Han_Title {
    flex: 5;
    width: 100%;
    display: flex;
    align-items: center;
    height: 60px;
    text-align: left;
    padding-left: 10px;
  }
  .Han_People {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    text-align: center;
  }
}
.Link {
  text-decoration: none;
  color: #707070;
}

@media screen and (max-width: 768px) {
  .Board {
    display: flex;
    align-items: center;
    height : 60px;
    .Han_Number {
      padding: 3px;
    }
    .Han_LastDate {
      padding: 3px;
      display: block;
      max-width: 220px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height : unset;
    }
    .Han_Title {
      padding: 3px;
      height : unset;
      display: block;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .Han_People {
      padding: 3px;
      display: block;
      max-width: 220px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height : unset;
    }
  }
}
