.Chart_Container {
  .Chart_TitleBox {
    .Chart_TextBox {
      display: flex;
      padding-top: 61px;
      .Three_icon_area {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        > img {
          width: 23px;
          height: 23px;
        }
      }
      .Chart_Text {
        font-size: 20px;
        color: #da2320;
        padding-left: 5px;
        font-weight: 500;
      }
    }
    .Example {
      font-size: 18px;
      padding-top: 21px;
      padding-bottom: 21px;
      color: #707070;
    }
    .Chart_Box2 {
      border-bottom: solid 1px #535353;
      .Chart_Type2 {
        display: flex;

        font-size: 16px;
        font-weight: 600;
        justify-content: space-around;
        border-top: solid 2px #535353;
        border-bottom: solid 1px #535353;
        .Type_Text {
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 170px;
          width: 100%;
          height: 60px;
        }
        .Type_Text2 {
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 160px;
          width: 100%;
          height: 60px;
        }
        .Type_Text3 {
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 210px;
          width: 100%;
          height: 60px;
        }
        .Type_Text4 {
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 310px;
          width: 100%;
          height: 60px;
        }
      }
      .Chart_ListBox2 {
        .Text_List2 {
          display: flex;

          border-bottom: solid 1px #e5e5e5;
          .One_Text2 {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 160px;
            width: 100%;
            height: 60px;
          }
          .Two_Text2 {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 180px;
            width: 100%;
            height: 60px;
          }
          .Three_Text2 {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 180px;
            width: 100%;
            height: 60px;
          }
          .Three_Text3 {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 390px;
            width: 100%;
            flex-direction: column;
            height: 60px;
            margin: auto;
          }
          .Three_Plus {
            display: flex;
            justify-content: left;
            align-items: center;
            max-width: 390px;
            width: 100%;
            height: 30px;
          }
        }
      }
      .Chart_TimeBox {
        padding-top: 23px;
        padding-bottom: 23px;
        line-height: 1.88;
        .Time {
          font-size: 16px;
          > span {
            font-weight: 600;
          }
        }
        .Holiday {
          font-size: 16px;
          > span {
            font-weight: 600;
          }
        }
        .Distance {
          font-size: 16px;
          > span {
            font-weight: 600;
          }
        }
        .Exception {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Chart_Container {
    .Chart_TitleBox {
      .Chart_TextBox {
        padding-left: 15px;
        .Chart_Text {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
        }
      }
      .Example {
        padding-left: 10px;
        padding-top: 30px;
        font-size: 12px;
      }
    }

    .Chart_Box2 {
      .Chart_Type2 {
        font-size: 14px;

        .Type_Text {
        }
        .Type_Text2 {
        }
        .Type_Text3 {
        }
        .Type_Text4 {
          display: none !important;
        }
      }
      .Chart_ListBox2 {
        .Text_List2 {
          font-size: 10px;
          .One_Text2 {
            justify-content: left;
            max-width: 125px;
          }
          .Two_Text2 {
            justify-content: left;
            max-width: 240px;
          }
          .Three_Text2 {
            justify-content: left;
            max-width: 250px;
          }
          .Three_Text3 {
            display: none !important;
          }
        }
      }
      .Chart_TimeBox {
        text-align: center;
        .TimeBox_wrap {
          .Time {
            font-size: 11px;
            > span {
            }
          }
          .Holiday {
            font-size: 11px;
            > span {
            }
          }
        }

        .Distance {
          font-size: 11px;
          > span {
          }
        }
        .Exception {
          font-size: 9px;
        }
      }
    }
  }
}
