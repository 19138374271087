.Text_Container {
  max-width: 1050px;
  width: 100%;
  padding-left: 85px;
  .Analy_TextBox {
    .Analy_Title {
      margin-top: 60px;
      font-size: 30px;
      padding-bottom: 41px;
      font-weight: 600;
      > span {
        color: #da2320;
      }
    }
    .Analy_Text {
      font-size: 20px;

      padding-bottom: 29px;
      color: #707070;
    }
  }
  .Text {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    color: #707070;
  }
}

@media screen and (max-width: 768px) {
  .Text_Container {
    padding-left: initial;
    margin-left: initial;

    .Analy_TextBox {
      br {
        display: none;
      }
      padding: 0 20px 0 20px;
      .Analy_Title {
        font-size: 20px;
      }
      .Analy_Text {
        font-size: 15px;
      }
    }
    br {
      display: none;
    }
    .Text {
      padding: 0 20px 0 20px;
      font-size: 13px;
      font-weight: initial;
      > br {
        display: none;
      }
    }
  }
}
