.TextContainer {
  max-width: 776px;
  width: 100%;
  margin-left: 85px;
  .Wrap_Line {
    .Title {
      margin-top: 60px;
      font-size: 30px;
      padding-bottom: 28px;
      font-weight: 600;
      span {
        color: #da2320;
      }
    }
    .VisitText {
      font-size: 20px;
      padding-bottom: 57px;
      color: #707070;
    }
    .Text {
      width: 830px;
      font-size: 16px;
      line-height: 1.5;
      padding-bottom: 100px;
      color: #707070;
    }
  }
}

@media screen and (max-width: 768px) {
  .TextContainer {
    margin-left: 0;
    .Wrap_Line {
      padding: 0 25px 0 25px;
      .Title {
        font-size: 20px;
      }
      .VisitText {
        font-size: 23px;
      }
      .Text {
        max-width: 830px;
        width: 100%;
        font-size: 13px;
        padding-bottom: 60px;
        br {
          display: none;
        }
      }
    }
  }
}
