.Analysis_Container {
  margin: 0px;
}
.Type_Container {
  max-width: 1050px;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  .Type_TextBox {
    .Type_Text {
      font-size: 14px;
      padding-bottom: 19px;
      font-weight: 600;
    }
    .Type_Title {
      font-weight: 600;
      font-size: 30px;
      padding-bottom: 5px;
      > span {
        color: #da2320;
      }
    }
  }
  // .Chart {
  .Chart_TitleBox {
    .Chart_Title {
      font-weight: 500;
      font-size: 15px;
      color: #707070;
      margin: 45px 0 21px 0;
    }
  }
  .Chart_Box {
    border-top: solid 2px #535353;
    max-width: 1050px;
    width: 100%;
    .TypeBox {
      height: 63px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f5f4f4;
      border-bottom: 1px solid #535353;
      //   &:nth-child(1) {
      //     color: #da2320;
      //   }
      //   &:nth-child(4) {
      //     color: #da2320;
      //   }
      //   &:nth-child(6) {
      //     color: #da2320;
      //   }
      .Type_1 {
        font-size: 14px;
        font-weight: 600;
        flex: 1;
        text-align: center;
      }
      .Type_2 {
        font-size: 14px;
        font-weight: 600;
        flex: 2;
        text-align: center;
      }
      .Type_3 {
        font-size: 14px;
        font-weight: 600;
        flex: 1;
        text-align: center;
      }
    }
    .Text_List {
      border-bottom: solid 1px #e5e5e5;
      max-width: 1050px;
      width: 100%;
      height: 63px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .One_1 {
        font-size: 14px;
        color: #707070;
        flex: 1;
        display: flex;
        justify-content: center;
      }
      .One_2 {
        font-size: 14px;
        color: #707070;
        flex: 2;
        display: flex;
        justify-content: center;
      }
      .One_3 {
        font-size: 14px;
        color: #707070;
        flex: 1;
        display: flex;
        justify-content: center;
      }
    }
    .Chart_Type_1 {
      height: 63px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f5f4f4;
      border-bottom: 1px solid #535353;
      .Type_One_1 {
        width: 230px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
      }
      .Type_Two_2 {
        width: 850px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
      }
    }
  }
  // }
}

@media screen and (max-width: 768px) {
  .Analysis_Container {
    margin: 0px 20px;
  }
  .Type_Container {
    padding-top: 29px;
    .Type_TextBox {
      .Type_Text {
        font-size: 11px;
      }
      .Type_Title {
        font-size: 20px;
      }
    }
    // .Chart {
    .Chart_TitleBox {
      .Chart_Title {
        padding-left: initial;
        margin: initial;
        font-size: 14px;
      }
    }
    .Chart_Box {
      .TypeBox {
        background-color: initial;
        .Type {
          width: 100%;
          text-align: center;
          font-size: 12px;
          font-weight: 600;
        }
      }
      .Text_List {
        .One {
          width: 100%;
          font-size: 10px;
          text-align: center;
        }
      }
    }
  }
  // }
}
