.Hboard_Contaier {
  .Hboard_TopLine {
    display: flex;
    max-width: 1050px;
    width: 100%;
    font-size: 16px;
    justify-content: space-around;
    align-items: center;
    height: 60px;
    border-top: 2px solid #535353;
    font-weight: 600;
    border-bottom: 1px solid #535353;
    .Hboard_Number {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .Hboard_CreatedDate {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .Hboard_Date {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .Hboard_LastDate {
      flex: 2;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .Hboard_Title {
      flex: 5;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .Hboard_People {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .Btn_Area {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
    .Write_Button {
      display: flex;
      background-color: #535353;
      font-size: 16px;
      color: #ffffff;
      max-width: 100px;
      width: 100%;
      justify-content: center;
      align-items: center;
      height: 44px;
      > a {
        text-decoration: none;
        color: #ffffff;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Hboard_Contaier {
    margin-top : 30px;
    .Hboard_TopLine {
      margin-top : 30px;
      font-size: 14px;
      border-top: 2px solid #535353;
      border-bottom: 1px solid #535353;
      .Hboard_Number{
        display: none;
      }
      .Hboard_Type {
      }
      .Hboard_Date {
      }
      .Hboard_Title {
        flex:2;
      }
      .Hboard_People {
        padding: 5px;
      }
      .Hboard_LastDate{
        display: none;
      }
    }
    .Han_Number{
      display: none;
    }
    .Han_People{
      flex:1;
    }
    .Han_CreatedDate{
      flex:2;
    }
    .Han_LastDate{
      display: none;
    }
    .Btn_Area {
      .Write_Button {
        font-size: 14px;
        max-width: 70px;
        width: 100%;
        margin-right: 10px;
        > a {
        }
      }
    }
    
  }
}
