.In_Container {
  max-width: 1050px;
  width: 100%;
  padding: 60px 0 0 0;
  padding-left: 85px;
  .In_TitleBox {
    .In_Title {
      font-size: 30px;
      padding-bottom: 30px;
      font-weight: 600;
    }
  }
  .In_SubTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    .In_SubText {
      font-size: 20px;
      color: #707070;
    }
  }
  .In_Contents {
    border-top: solid 2px #535353;
    border-bottom: solid 2px #535353;
    .In_Box {
      display: flex;
      max-width: 1050px;
      .In_Menu {
        width: 150px;
        font-size: 16px;
        color: #535353;
        height: 60px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #a0a0a0;
        border-right: 1px solid #a0a0a0;
      }
      .In_Content {
        width: 1000px;
        font-size: 16px;
        color: #707070;
        height: 60px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #a0a0a0;
        padding-left: 10px;
      }
    }
  }
  .BottomText {
    padding-top: 21px;
    font-size: 14px;
    padding-bottom: 30px;
  }
  .Go_Button {
    display: flex;
    background-color: #535353;
    font-size: 16px;
    color: #ffffff;
    width: 100px;
    justify-content: center;
    align-items: center;
    height: 44px;
    > a {
      color: #ffffff;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .In_Container {
    padding-left: 0;
    .In_TitleBox {
      padding-left: 15px;
      .In_Title {
        font-size: 18px;
      }
    }
    .In_SubTitle {
      padding: 0 15px 15px 10px;

      .In_SubText {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .Go_Button {
        margin-right: 5px;
        font-size: 12px;
        max-width: 80px;
        height: 35px;
        > a {
        }
      }
    }
    .In_Contents {
      margin: 0 20px;
      .In_Box {
        .In_Menu {
          font-size: 10px;
          flex: 1;
          justify-content: center;
        }
        .In_Content {
          font-size: 11px;
          flex: 5;
        }
      }
    }
    .BottomText {
      padding: 10px 10px 30px 20px;
      font-size: 11px;
    }
  }
}
