.ComeContainer {
  max-width: 1050px;
  padding-left: 85px;
  height: auto;
  .Come_TextBox {
    padding-top: 60px;

    .Come_Title {
      font-size: 30px;
      padding-bottom: 50px;
      font-weight: 600;
    }
    .Come_Box {
      display: flex;
      padding-bottom: 25px;
      .Come_One {
        max-width: 88px;
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        padding-right: 40px;
        color: #535353;
      }
      .Come_Two {
        font-size: 16px;
        font-weight: 200;
        color: #707070;
      }
    }
  }
  .Hanbit_Map {
    width: 1050px;
    height: 500px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 768px) {
  .ComeContainer {
    padding-left: 0px;
    .Come_TextBox {
      padding-left: 20px;
      .Come_Title {
        font-size: 20px;
        padding-bottom: 25px;
      }
      .Come_Box {
        display: flex;
        padding-bottom: 15px;
        .Come_One {
          font-size: 13px;
        }
        .Come_Two {
          font-size: 13px;
        }
      }
    }
    .Hanbit_Map {
      max-width: 768px;
      width: 100%;
      margin: auto;
    }
  }
}
