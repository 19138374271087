.CustomContainer {
  background-image: url(../../_asset/image/main/service.png);
  // max-width: 610px;
  background-position: right;
  background-repeat: no-repeat;
  width: 100%;
  height: 207px;
  margin: 0px 15px 0px 0px;
  background-color: #f5f4f4;
  border: solid 1px #d5d5d5;
  background-size: cover;
  .TextBox {
    display: block;
    padding: 32px 30px 0px 30px;
    .Title {
      color: #da2320;
      font-weight: 600;
      padding-bottom: 29px;
    }
    .Tell {
      font-size: 45px;
      font-weight: 600;
      line-height: 0.75;
      padding-bottom: 31px;
      color: #333333;
      a {
        color: #333333;
      }
    }
    .TellBox {
      font-size: 15px;
      line-height: 1.47;
      font-weight: 300;
      color: #707070;
    }
  }
}

@media screen and (max-width: 768px) {
  .CustomContainer {
    background-position-x: center;
    background-repeat: no-repeat;
    width: 100%;
    height: initial;
    margin: 30px 20px 0px 15px;
    background-color: #f5f4f4;
    border-radius: initial;

    .TextBox {
      display: block;
      padding: 10%;
      .Title {
        padding-bottom: 5%;
      }
      .Tell {
        font-size: 1rem;
        padding-bottom: 10%;
      }
      .TellBox {
        font-size: 0.8rem;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .CustomContainer {
    background-position-x: center;
    background-repeat: no-repeat;
    width: 100%;
    height: initial;
    margin: 30px 20px 0px 20px;
    background-color: #f5f4f4;
    width: initial;
    .TextBox {
      display: block;
      padding: 5%;
      .Title {
        padding-bottom: 5%;
      }
      .Tell {
        font-size: 1rem;
        padding-bottom: 5%;
      }
      .TellBox {
        font-size: 0.8rem;
      }
    }
  }
}
