.InHanbit_Container {
  max-width: 1050px;
  width: 100%;
  padding-left: 85px;
  .InHanbit_TitleBox {
    display: flex;
    justify-content: space-between;
    font-size: 30px;
    margin-top: 60px;
    padding-bottom: 18px;
    .InHanbit_Title {
      font-size: 30px;
      font-weight: 600;
    }
    .InHanbit_Btn {
      width: 100px;
      color: #ffffff;
      background-color: #535353;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      a {
        color: #fff;
      }
    }
  }
  .InHanbit_Board {
    border-top: 2px solid #535353;
    .In_TitleBox {
      .In_Title {
        display: flex;
        justify-content: left;
        align-items: center;
        font-size: 18px;
        color: #707070;
        height: 60px;
        border-bottom: 1px solid #e5e5e5;
        line-height: 1.67;
      }
    }
    .In_Menu {
      display: flex;
      border-bottom: 1px solid #e5e5e5;
      .In_Menu_People {
        font-size: 14px;
        color: #535353;
        height: 60px;
        display: flex;
        align-items: center;
      }
      .In_Menu_Date {
        font-size: 14px;
        color: #535353;
        height: 60px;
        display: flex;
        align-items: center;
      }
    }
    .In_Menu_Text {
      padding-bottom: 60px;
      font-size: 16px;
      color: #707070;
      border-bottom: 2px solid #535353;
      margin-top: 60px;
      // margin-bottom: 60px;
      white-space: pre-wrap;
    }
  }
}

@media screen and (max-width: 768px) {
  .InHanbit_Container {
    padding-bottom: 30px;
    padding-left: initial;
    .InHanbit_TitleBox {
      padding: 0 15px 18px 20px;
      .InHanbit_Title {
        font-size: 24px;
        padding-left: 0px;
      }
      .InHanbit_Btn {
        margin-right: 5px;
        font-size: 12px;
        width: 80px;
        height: 35px;
      }
    }
    .InHanbit_Board {
      margin: 0 20px;
      .In_TitleBox {
        .In_Title {
          font-size: 16px;
          // padding-left: 15px;
          height: 60px;
        }
      }
      .In_Menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // height: 35px;
        justify-content: left;
        padding : 10px 0px;
        > div{
          // margin-left : 10px;
          height : unset !important;
        }
        .In_Menu_People {
          font-size: 13px;
          padding-left: unset;
          
        }
        .In_Menu_Date {
          font-size: 13px;
        }
      }
      .In_Menu_Text {
        font-size: 14px;
        padding : 30px 0px;
        height: initial;
        margin-bottom: 30px;
        margin-top: initial;
      }
    }
  }
}
