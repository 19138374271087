.BoardContainer {
  max-width: 1250px;
  min-width: 1250px;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .BoardContainer {
    min-width: initial;
    flex-direction: column;
  }
}
