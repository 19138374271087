.Notice {
  max-width: 1250px;
  width: 100%;
  margin: auto;
  .TitleBox {
    display: flex;
    justify-content: space-between;
    padding-top: 60px;
    padding-bottom: 25px;
    .Title {
      font-size: 20px;
      font-weight: 600;
      color: #333333;
    }
  }
  .TextBox {
    padding-bottom: 60px;
    .Box {
      display: flex;
      justify-content: space-between;
      .Text {
        line-height: 2.27;
        color: #535353;
        font-weight: 600;
      }
      .Date {
        line-height: 2.27;
        font-size: 15px;
        color: #a0a0a0;
      }
    }
  }
}

a {
  color: #da2320;
  &:hover {
    color: #da2320;
  }
}

@media screen and (max-width: 768px) {
  .Notice {
    padding: 0 25px 0 25px;
    .TitleBox {
      padding-top: 38px;
    }
    .TextBox {
      padding-bottom: 20px;
      .Box {
        .Text {
          font-size: 13px;
        }
        .Date {
          font-size: 13px;
        }
      }
    }
  }
}
