.Button_Container {
  min-width: 1050px;
  width: 100%;
  border-top: 1px solid #ddd;
  padding-top: 20px;
  margin: 0 auto;
  .Back_Button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 40px;
    border: 1px solid #ccc;
    font-size: 16px;
    font-weight: 600;
    color: #333;
    background-color: #fff;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s, transform 0.3s;

    &:hover {
      background-color: #f0f0f0;
      transform: scale(1.05);
    }
  }
}

.Post_Header {
  min-width: 1050px;
  width: 100%;
  gap: 15px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  padding-top: 20px;
  margin: 60px auto 0;
  .Post_Title {
    font-size: 36px;
    font-weight: bold;
    margin: 0;
  }

  .Post_Info {
    display: flex;
    gap: 30px;
    font-size: 14px;
    color: #707070;
    .category {
      color: #707070;
    }
    .content {
      color: #000;
    }
  }
}

.Post_Footer {
  min-width: 1050px;
  width: 100%;
  margin-bottom: 20px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  padding-top: 20px;
  margin: 60px auto 0;

  .Post_Title {
    display: flex;
    align-items: center;
    gap: 50px;
    font-size: 14px;
    color: #555;
    margin-bottom: 10px;

    .Previous_Post,
    .Next_Post {
      font-weight: bold;
    }
    .Current_Post {
      cursor: pointer;
    }
  }
}

.Vehicle_Contatiner {
  max-width: 1250px;
  min-width: 1250px;
  margin: auto;
  height: auto;
  .TopBanner {
  }
  .Vehicle_Contents {
    display: flex;
  }
  .Result {
    max-width: 1050px;
    margin: auto;
  }
}

@media screen and (max-width: 768px) {
  .Vehicle_Contatiner {
    min-width: initial;
    .TopBanner {
    }
    .Vehicle_Contents {
      flex-direction: column;
    }
    .Result {
    }
  }
}
