.Container {
  background-image: url(../../_asset/image/main/location.png);
  background-position: right;
  background-repeat: no-repeat;
  width: 100%;
  height: 207px;
  margin: 0px 0px 0px 15px;
  background-color: #f5f4f4;
  border: solid 1px #d5d5d5;
  background-size: cover;
  .TextBox {
    display: block;
    padding: 32px 31px;

    .Title {
      color: #da2320;
      font-weight: 600;
      line-height: 1.88;
      padding-bottom: 27px;
    }
    .Location {
      font-size: 40px;
      font-weight: 600;
      line-height: 0.75;
      padding-bottom: 28px;
    }
    .Adress {
      font-size: 15px;
      font-weight: 300;
      line-height: 1.47;
    }
  }
}

@media screen and (max-width: 768px) {
  .Container {
    background-position-x: center;
    background-repeat: no-repeat;
    width: 100%;
    height: initial;
    margin: 10px 20px 20px 5px;
    background-color: #f5f4f4;
    border-radius: initial;
    .TextBox {
      padding: 10%;
      .Title {
        padding-bottom: 5%;
      }
      .Location {
        font-size: 1rem;
        padding-bottom: 10%;
      }
      .Adress {
        font-size: 0.8rem;
      }
      .Adress br {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .Container {
    background-position-x: center;
    background-repeat: no-repeat;
    width: initial;
    height: initial;
    margin: 10px 20px 20px 20px;
    background-color: #f5f4f4;
    .TextBox {
      padding: 5%;
      .Title {
        padding-bottom: 5%;
      }
      .Location {
        font-size: 1rem;
        padding-bottom: 5%;
      }
      .Adress {
        font-size: 0.8rem;
      }
      .Adress br {
        display: initial;
      }
    }
  }
}
