.Vehicle_Container {
  padding-left: 85px;
  max-width: 1050px;
  width: 100%;
  .Vehicle_TitleBox {
    padding-top: 60px;
    .Vehicle_Title {
      font-size: 30px;
      font-weight: 600;
      padding-bottom: 30px;
    }
  }
  .Vehicle_TopLine {
    display: flex;
    font-size: 16px;
    max-width: 1050px;
    width: 100%;
    height: 60px;
    justify-content: space-between;
    border-top: solid 2px #535353;
    border-bottom: solid 2px #535353;
    .Top_Line_1 {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
    }
    .Top_Line_2 {
      width: 100%;
      padding-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
    }
    .Top_Line_3 {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 3.5;
    }
    .Top_Line_4 {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
    }
  }
  .Vehicle_Board {
    justify-content: space-between;
    max-width: 1050px;
    width: 100%;
    .Board_List {
      display: flex;
      max-width: 1050px;
      width: 100%;
      justify-content: space-between;
      border-bottom: solid 1px #e5e5e5;
      > a {
        text-decoration: none;
        color: #535353;
      }
      .List_One {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        > div {
        }
      }
      .List_Two {
        width: 100%;
        height: 60px;
        padding-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
      }
      .List_Three {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: left;
        align-items: center;
        flex: 5;
      }
      .List_Four {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Vehicle_Container {
    padding-left: initial;
    .Vehicle_TitleBox {
      padding: 60px 20px 0 20px;
      .Vehicle_Title {
        font-size: 24px;
        padding-left: initial;
      }
    }
    .Vehicle_TopLine {
      .Top_Line_1 {
        font-size: 14px;
      }
      .Top_Line_2 {
        font-size: 14px;
        padding-right: initial;
      }
      .Top_Line_3 {
        font-size: 14px;
      }
      .Top_Line_4 {
        font-size: 14px;
      }
    }
    .Vehicle_Board {
      .Board_List {
        > a {
        }
        .List_One {
          > div {
          }
        }
        .List_Two {
          padding-right: initial;
        }
        .List_Three {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .List_Four {
        }
      }
    }
  }
}

.custom-select {
  position: relative;
  display: inline-block;
  width: 110px;
  margin-bottom: 40px;
  margin-right: 10px;

  .select-button {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    outline: none;
    box-sizing: border-box;

    .arrow {
      margin-left: 8px;
      font-size: 12px;
    }
  }

  .select-options {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
    list-style: none;
    padding: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    box-sizing: border-box;
    max-height: 150px;
    overflow-y: auto;

    .option {
      padding: 10px 15px;
      font-size: 14px;
      cursor: pointer;
      transition: background-color 0.2s;
      &:hover {
        background-color: #eee;
      }
    }
  }
}
