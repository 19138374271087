.FooterContainer {
  max-width: 1250px;
  min-width: 1250px;
  margin: auto;
  height: 171px;
  width: 100%;
  .Box {
    display: flex;
    padding-top: 40px;
    justify-content: space-between;
    .Footer_wrap {
      display: flex;
      .LogoBox {
        max-width: 118px;
        width: 100%;
        margin-right: 38px;
        > img {
          width: 100%;
        }
      }
      .Info {
        font-size: 14px;
        font-weight: 200;
        line-height: 1.71;
        color: #707070;
        .Top_Wrap {
          display: flex;
          .Ceo {
            padding-right: 10px;
          }
          .Tell {
          }
        }
        .Middle_Wrap {
          display: flex;
          .address {
            padding-right: 10px;
          }
          .E-mail {
          }
        }
        .copy {
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .FooterContainer {
    min-width: initial;
    padding: 0 15px 0 15px;
    height: initial;
    margin-bottom: 20px;
    .Box {
      padding-top: 10px;
      .Footer_wrap {
        display: flex;
        .LogoBox {
          padding-right: 10px;
          display: none;
        }
        .Info {
          padding: 20px 0 20px 0;
          font-size: 12px;
          .Top_Wrap {
            display: block;
            .Ceo {
            }
            .Tell {
            }
          }
          .Middle_Wrap {
            display: block;
            .address {
            }
            .E-mail {
            }
          }
          .copy {
          }
        }
      }
    }
  }
}
