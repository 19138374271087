body,
html {
  padding: 0;
  margin: 0;
  font-family: NanumSquareOTF;
}

@import "~antd/dist/antd.css";

@font-face {
  font-family: "NanumSquareOTF";
  font-style: normal;
  font-weight: 900;
  src: url("../src/_asset/fonts/NanumSquareOTF_acEB.otf");
}
@font-face {
  font-family: "NanumSquareOTF";
  font-style: normal;
  font-weight: 700;
  src: url("../src/_asset/fonts/NanumSquareOTF_acB.otf");
}
@font-face {
  font-family: "NanumSquareOTF";
  font-style: normal;
  font-weight: 500;
  src: url("../src/_asset/fonts/NanumSquareOTF_acR.otf");
}
@font-face {
  font-family: "NanumSquareOTF";
  font-style: normal;
  font-weight: 300;
  src: url("../src/_asset/fonts/NanumSquareOTF_acL.otf");
}

.custom-table {
  .ant-table-thead > tr > th {
    border-top: 1px solid #999;
    border-bottom: 1px solid #ccc;
    text-align: center;
    font-weight: bold;
    padding: 10px 30px;
  }

  .ant-table-tbody > tr > td {
    text-align: center;
    border-bottom: 1px solid #eee;
  }
}
