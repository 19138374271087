.Write_HanbitBoard_Container{
    max-width: 1050px;
    width: 100%;
    .Write_HanbitBoard_Title{
        padding-top: 60px;
        font-size : 30px;
        text-align: left;
        font-weight : 600;
    }
    .Write_HanbitBoard_table{
        
    }
    .privacy-wrap{
        padding-top: 30px;
        text-align: left;
        max-width: 700px;
        .title{
            font-size: 20px;
            text-align: inherit;
            margin-bottom: 14px;
            padding-left: 10px;
        }
        .privacy-box{
            font-size: 16px;
            text-align: inherit;
            color: #333333;
            padding: 26px 21px;
            background: #f0f0f2;
            margin-bottom: 17px;
            >div{
                text-align: inherit;
            }
        }
        .checkbox{
            text-align: inherit;
            display: flex;
            align-items: center;
            input[type="checkbox"]{
                
                ~ label{
                // background: url("../../_asset/image/inputbutton.png");
                
                margin-left: 10px;
                background-repeat: no-repeat;
                display: flex;
                align-items: center;
                cursor: pointer;
                background-position-y: -24px;
                padding-right: 15px;
                font-size: 13px;
                }
                &:checked{
                    ~ label{
                        background-position-y:1px;
                    }
                    
                }
            }
        }
    }
    .Title_input{
        max-width: 300px;
        font-size: 20px;
        width: 100%;
        height: 40px;
    }
    .Name_input{
        max-width: 300px;
        font-size: 20px;
        width: 100%;
        height: 40px;
    }
    .Password_input{
        max-width: 300px;
        font-size: 20px;
        width: 100%;
        height: 40px;
    }
    .Text_input{
        max-width: 600px;
        font-size: 20px;
        width: 100%;
        height: 600px;
    }
    .register-button-container{
        margin-top : 30px;
        display: flex;
        justify-content: center;
        .register-button{
            height: fit-content;
            max-width: 200px;
            width : 100%;
            border: 1px solid rgba(51, 51, 51, 0.9);
            color: #1f2738;
            display: flex;
            justify-content: space-between;
            p{
                font-size: 14px;
                padding: 6px 10px;
                margin: 0;
            }
        }
    }
}
.leftmenu{
    padding-top: 15px;
    font-size: 20px;
    font-weight : 100;
    padding-bottom: 15px;
}

.CommunityReviewWriter-container .table{
    border-top: 1px solid #1f2738;
    border-bottom: 1px solid #1f2738;
    margin-bottom: 30px;
}


@media screen and (max-width:768px) {
    .Write_HanbitBoard_Container{
        
        .Write_HanbitBoard_Title{
            padding-left: 15px;
        }
        .Write_HanbitBoard_table{
            padding-left: 15px;
        }
        .privacy-wrap{
            
            .title{
                
            }
            .privacy-box{
                
                >div{
                    
                }
            }
            .checkbox{
                padding-left: 15px;
                input[type="checkbox"]{
                    
                    ~ label{
                    
                    }
                    &:checked{
                        ~ label{
                            
                        }
                        
                    }
                }
            }
        }
        .Title_input{
           
        }
        .Name_input{
            
        }
        .Password_input{
            
        }
        .Text_input{
            width: 93%;
        }
        .register-button-container{
            
            .register-button{
                
                p{
                    
                }
            }
        }
    }
    .leftmenu{
        
    }
    
    .CommunityReviewWriter-container .table{
        
    }
}


