.LicenseContainer {
  background-image: url(../../../_asset/image/main/banner-01.png);
  width: 100%;
  height: 140px;
  flex: 1;
  background-position: right;
  background-repeat: no-repeat;
  background-color: #f5f4f4;
  border: solid 1px #d5d5d5;
  margin-right: 15px;
  margin-top: 20px;
  background-size: cover;
  .Link {
    text-decoration: none;
    color: #333333;
  }
  .TextBox {
    padding: 36px 0 0 31px;
    .Title_license {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.18;
      padding-bottom: 14px;
      color: #333333;
    }
    .Text {
      font-size: 15px;
      color: #333333;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 768px) {
  .LicenseContainer {
    .Link {
    }
    .TextBox {
      .Title_license {
      }
      .Text {
        font-size: 11px;
      }
    }
  }
}
