.Result_Container {
  padding-top: 78px;
  .Cards_area {
    .Cards_wrap {
      padding-bottom: 10px;
    }
  }
  .Result_TextBox {
    .Result_Text {
      font-size: 14px;
      font-weight: 600;
      padding-bottom: 19px;
    }
    .Result_Title {
      font-weight: 600;
      font-size: 30px;
      padding-bottom: 20px;
      > span {
        color: #da2320;
      }
    }
  }
  .Type_Box {
    .List_box {
      .List_TextBx {
        display: flex;
        margin-bottom: 5px;
        margin-top: 15px;
        .List_Number {
          color: #da2320;
          font-size: 30px;
          font-weight: 900;
          margin-right: 10px;
        }
        .List_Title {
          font-size: 26px;
          font-weight: 600;
          display: flex;
          align-items: center;
        }
      }
      .List_Text {
        font-size: 16px;
        line-height: 1.7;
        padding-left: 30px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Result_Container {
    padding: 60px 20px 0 20px;
    .Result_TextBox {
      .Result_Text {
        font-size: 11px;
      }
      .Result_Title {
        font-size: 20px;
      }
    }
    .Cards_area {
      .Cards_wrap {
        padding-bottom: 15px;
      }
    }
  }
}
