.IntroContatiner {
  min-width: 1250px;
  height: auto;
  .TopBanner {
  }
  .Intro_Contents {
    max-width: 1250px;
    margin: auto;
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .IntroContatiner {
    min-width: initial;
    .Intro_Contents {
      flex-direction: column;
    }
  }
}
