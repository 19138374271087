.HisContainer {
  max-width: 1050px;
  width: 100%;
  height: auto;
  padding-bottom: 100px;
  padding-left: 85px;
  .HisTextBox {
    padding-top: 60px;
    .His_Title {
      font-size: 30px;
      padding-bottom: 28px;
      font-weight: 600;
      > span {
        color: #da2320;
        font-weight: 600;
      }
    }
    .His_Text {
      font-size: 20px;
      padding-bottom: 60px;
      color: #707070;
    }
  }
  .His_List {
    border-top: 2px solid #535353;
    border-bottom: 2px solid #535353;
    max-width: 1050px;
    width: 100%;
    .Table_list {
      max-width: 1050px;
      display: flex;
      height: 100px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e5e5e5;

      .Box_Year {
        flex: 1;
        width: 100%;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #535353;
        font-weight: 600;
      }
      .Box_Month {
        flex: 1;
        font-size: 16px;
        font-weight: 600;
        color: #535353;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .Box_Text {
        font-size: 16px;
        flex: 5;
        width: 100%;
        color: #707070;
      }
    }
  }
}
.ant-table-thead {
  font-size: 16px;
  font-weight: 600;
  .ant-table-cell {
    text-align: center;
  }
}
.ant-table-tbody {
  .ant-table-cell {
    color: #707070;
    padding: 16px 25px;
  }
}
.ant-pagination-item-active a {
  color: #da2320;
}
.ant-pagination-item-active {
  border-color: #da2320 !important;
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #da2320 !important;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #da2320;
  border-color: #da2320 !important;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #da2320 !important;
}
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #da2320;
  border-color: #da2320;
}
.ant-pagination-prev:hover .ant-pagination-item-link {
  color: #da2320;
  border-color: #da2320;
}
@media screen and (max-width: 768px) {
  .HisContainer {
    padding-left: initial;
    .HisTextBox {
      margin-left: initial;
      padding: 60px 20px 0 20px;
      .His_Title {
        font-size: 20px;
      }
      .His_Text {
        font-size: 15px;
      }
    }
    .His_List {
      width: initial;
      margin: 0 20px;
      .Table_list {
        .Box_Year {
          font-size: 13px;
          flex: 1.2;
        }
        .Box_Month {
          font-size: 13px;
        }
        .Box_Text {
          font-size: 12px;
        }
      }
    }
  }
}
