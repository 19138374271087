.FindContainer{
    max-width: 1250px;
    width: 100%;
    text-align: center;
    height: 700px;
    margin: auto;
    .FindWrap{
        .TitleBox{
            padding-top: 100px;
            .Title{
                font-size: 30px;
                padding-bottom: 14px;
            }
            .Text{
                font-size: 16px;
                color: #a0a0a0;
                padding-bottom: 34px;
                border-bottom: 2px solid #535353;
                max-width: 392px;
                margin: auto;
            }
        }
        .WrapContents {
            display: flex;
            max-width: 400px;
            margin: auto;
            margin-top: 34px;
        }
        .EmailFind{
            width: 302px;
            height: 50px;
            margin-right: 10px;
            
            
        }
        .Btn{
            color: #ffffff;
            width: 80px;
            height: 50px;
            background-color: #535353;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}