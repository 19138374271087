.GuideContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
    .GuideTop {
        display: flex;
        max-width: 600px;
        >div{
            padding: 15px 0 !important;
        }
        .GuideAbout{
            
            display: flex;
            width: 150px;
            padding: 5px;
            cursor: pointer;
            justify-content:center;
            font-weight: 600;
            color: #333333;
            >a{
                text-decoration: none;
                
            }
            a:active {
                color:#da2320;
            }
            
            
        }
        .GuideInfo{
            display: flex;
            width: 150px;
            padding: 5px;
            cursor: pointer;
            font-weight: 600;
            justify-content:center;
            color: #333333;
            >a{
                text-decoration: none;

                color:#070707;
            }
        }
        .GuideCar{
            display: flex;
            width: 150px;
            padding: 5px;
            cursor: pointer;
            font-weight: 600;
            justify-content:center;
            color: #333333;
            >a{
                text-decoration: none;

                color:#070707;
            }
        }
        .GuideBoard{
            display: flex;
            width: 150px;
            padding: 5px;
            cursor: pointer;
            font-weight: 600;
            justify-content:center;
            color: #333333;
            >a{
                text-decoration: none;

                color:#070707;
            }
        }
        .GuideNotice{
            display: flex;
            width: 150px;
            padding: 5px;
            cursor: pointer;
            font-weight: 600;
            justify-content:center;
            color: #333333;
            >a{
                text-decoration: none;

                color:#070707;
            }
        }
        .GuideQnA{
            display: flex;
            width: 150px;
            padding: 5px;
            cursor: pointer;
            justify-content:center;
            font-weight: 600;
            color: #333333;
            >a{
                text-decoration: none;

                color:#070707;
            }
        }
    }
}

@media screen and (max-width:768px) {
    .GuideContainer{
        display: none;
    }
}