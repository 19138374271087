.CertificateContainer {
  .Certi_TitleBox {
    margin-top: 70px;
    .Certi_Title {
      font-size: 30px;
      font-weight: 600;
      padding-bottom: 31px;
      > span {
        color: #da2320;
      }
    }
    .Certi_Text {
      font-size: 18px;
      font-weight: 500;
      color: #494e54;
      padding-bottom: 18px;
    }
  }
  .Certi_TextBox {
    background-color: #f4f4f4;
    padding: 20px;
    display: flex;
    .Certi_List {
      max-width: 80px;
      width: 100%;
      display: flex;
      color: #535353;
      flex-direction: column;
      padding-bottom: 14px;
      font-weight: 600;
      .Certi_One {
        padding-bottom: 13px;
      }
    }
    .Certi_List2 {
      .Certi_Wrap {
        display: flex;
        .Certi_One {
          padding-bottom: 13px;
        }
      }
      .Certi_Wrap2 {
        display: flex;
        flex-direction: column;
        .Certi_Two {
          max-width: 800px;
          width: 100%;
          padding-bottom: 10px;
          font-weight: 500;
        }
        .Certi_Three {
          max-width: 855px;
          width: 100%;
          padding-bottom: 10px;
          color: #a0a0a0;
          font-weight: 300;
          line-height: 1.7;
        }
      }
    }
  }
  .Certi_ListBox {
    padding-top: 40px;
    display: flex;
    justify-content: space-around;
    .Certi_MapBox {
      display: flex;
      .Certi_map_area {
        max-width: 135px;
        .Certi_map_img {
          max-width: 135px;
          width: 100%;
          margin-bottom: 31px;
          > img {
            width: 100%;
          }
        }
        .Certi_map_text_box {
          text-align: center;
          .Certi_map_text {
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
      .Certi_map_arrow_box {
        margin-left: 45px;
        display: flex;
        align-items: center;
        .Certi_arrow_wrap {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .Certi_map_arrow {
            .Map_arrow {
              padding-bottom: 13px;
            }
          }
          .Certi_map_arrow_text {
            font-size: 16px;
            color: #da2320;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .CertificateContainer {
    .Certi_TitleBox {
      .Certi_Title {
        padding: 15px 20px 15px 20px;
        font-size: 20px;
        padding-bottom: 15px;
      }
      .Certi_Text {
        padding-left: 20px;
        font-size: 14px;
      }
    }
    .Certi_TextBox {
      padding: 20px;
      margin-bottom: 20px;
      .Certi_List {
        max-width: 60px;
        .Certi_One {
          font-size: 12px;
          margin-right: 20px;
        }
        .Certi_Two {
        }
        .Certi_Three_ {
          font-size: 12px;
        }
      }
      .Certi_List2 {
        .Certi_Wrap {
          .Certi_One {
            font-size: 12px;
            width: 100%;
            justify-content: center;
          }
        }
        .Certi_Wrap2 {
          .Certi_Two {
            font-size: 12px;
          }
          .Certi_Three {
            font-size: 11px;
          }
        }
      }
    }
    .Certi_ListBox {
      display: none;
    }
  }
}
