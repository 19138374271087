.Mobile_link {
  color: #333333;
  text-decoration: none;
}

.DropDown_container {
  width: 100%;
  position: relative;
}
.MuiPaper-root {
  left: 0 !important;
}
.MuiButton-contained {
  box-shadow: none;
}
.Arrowicon {
  position: absolute;
  right: 20px;
  display: flex;
  top: 10px;
}
.MuiSvgIcon-root {
}
