.Contents {
  max-width: 1250px;
  min-width: 1250px;
  margin: auto;
  padding-top: 50px;
  .One_Line {
    display: flex;
    margin-bottom: 10px;
    .Link {
      text-decoration: none;
      color: #333333;
    }
  }
  .Two_Line {
    display: flex;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 768px) {
  .Contents {
    min-width: initial;
    padding-top: 0;
    .One_Line {
      flex-direction: initial;
    }
    .Two_Line {
      display: none;
    }
  }

  @media screen and (max-width: 450px) {
    .Contents {
      min-width: initial;
      padding-top: 0;
      .One_Line {
        flex-direction: column;
      }
      .Two_Line {
        display: none;
      }
    }
  }
}
