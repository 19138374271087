.MainInfoContainer {
  width: 100%;
  margin: auto;
  background-color: #f5f4f4;
  // height: 300px;
  text-align: center;
  .InfoTitle {
    padding-top: 36px;
    font-size: 20px;
    font-weight: 600;
    span {
      color: #da2320;
    }
  }
  .MapBox {
    // max-width: 1250px;
    // width: 100%;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    .ListBox {
      padding: 35px;
      .Link {
        text-decoration: none;
        color: #535353;
        font-weight: 600;
      }
    }
    .IconBox {
      max-width: 120px;
      // border: solid 1.4px #535353;
      // clip-path: ellipse(58px 58px);
      // -webkit-clip-path: ellipse(58px 58px);
      > img {
        width: 100%;
      }
    }
    .TextBox {
      margin-top: 22px;
    }
  }
}

@media screen and (max-width: 768px) {
  .MainInfoContainer {
    min-width: initial;
    max-width: initial;
    background-color: #f5f4f4;
    // height: 300px;
    text-align: center;
    background-color: initial;
    .InfoTitle {
      display: none;
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 20px;
      font-weight: 600;
      span {
        color: #da2320;
      }
    }
    .MapBox {
      max-width: initial;
      width: initial;
      margin-left: 15px;
      margin-right: 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: initial;
      .ListBox {
        padding: 3%;
        background-color: #fff;
        border: 1px solid #d5d5d5;
        flex: 1;
        margin: 5px;
        // -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.08);
        // -moz-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.08);
        // box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.08);
        .Link {
          text-decoration: none;
          color: #535353;
          font-weight: 600;
        }
      }
      .IconBox {
        max-width: 86px;
        -webkit-clip-path: ellipse(33px 36px);
        clip-path: ellipse(33px 36px);
        > img {
          width: 100%;
        }
      }
      .TextBox {
        margin-bottom: 20px;
        margin-top: initial;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .MainInfoContainer {
    min-width: initial;
    // height: 800px;
    .MapBox {
      flex-wrap: wrap;
    }
  }
}
